/** @jsx jsx */
import { jsx, Box, Button, Heading, Text } from 'theme-ui';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoIosCloseCircle } from 'react-icons/io';
import { rgba } from 'polished';
import { useI18next } from 'gatsby-plugin-react-i18next';

const PriceTable = ({ price, isAnnual, openModal }) => {
  const { t } = useI18next();

  return (
    <Box
      sx={styles.priceTable}
      className={`priceCard ${t(price.isRecommended) === true ? 'recommended' : ''}`}
    >
      <Box sx={styles.header} className="priceHeader">
        <Heading as="h3" sx={styles.title}>
          {t(price.title)}
        </Heading>
        <Text as="p" sx={styles.priceAmount}>
          {isAnnual
            ? t(price?.amount?.annual)
            : t(price?.amount?.monthly)
          }
          {` DZD`}/mo
        </Text>
        <Text as="p" sx={styles.subtitle}>
          {t(price.subtitle)}
        </Text>
      </Box>
      <Box as="ul" sx={styles.list}>
        {price?.features?.map((feat) => (
          <li key={`feat-item-${feat.id}`} className={!feat.isAvailable ? 'unavailable' : ''}>
            {t(feat.isAvailable) ? (
              <span>
                <IoMdCheckmarkCircle sx={{ color: 'primary' }} size="23px" />
              </span>
            ) : (
              <span>
                <IoIosCloseCircle color="#CED7E1" size="23px" />
              </span>
            )}
            <span>{t(feat.title)}</span>
          </li>
        ))}
      </Box>
      <Box className="priceButton">
        <Button
          // as={"a"}
          onClick={openModal}
          sx={styles.button}
          variant="primaryMd"
          href={"#"}
          rel={"noopener"}
        >
          {t(price.buttonText)}
        </Button>
      </Box>
    </Box>
  );
};

export default PriceTable;

const styles = {
  priceTable: {
    width: "100%",
    background: 'white',
    borderRadius: 10,
    position: 'relative',
    padding: ['40px 10px', null, null, null, '45px 50px 70px'],
    boxShadow: '0 2px 0 rgba(54, 45, 89, 0.15),-0.0175rem -0.0175rem 0 0.0175rem #2B9EF8,0 0 0 0.0175rem #7536C3',
    '&.recommended': {
      boxShadow: '0 2px 0 rgba(54, 45, 89, 0.15),-0.1875rem -0.1875rem 0 0.1875rem #2B9EF8,0 0 0 0.375rem #7536C3',
      padding: ['55px 20px', null, null, null, '65px 60px 90px'],
      button: {
        backgroundColor: 'primary',
        color: 'white',
        ':hover': {
          backgroundColor: 'secondary',
        },
      },
    },
  },
  header: {
    borderBottom: `1px solid #E0E2E4`,
    pb: [6],
  },
  title: {
    fontWeight: 700,
    fontSize: 5,
    lineHeight: 1.31,
    letterSpacing: '-0.55px',
  },
  priceAmount: {
    color: 'primary',
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: 1.39,
    letterSpacing: 'heading',
    mt: [2],
  },
  subtitle: {
    color: rgba('#343D48', 0.65),
    lineHeight: 1.62,
    maxWidth: 290,
    mt: [4],
  },
  list: {
    borderBottom: `1px solid #E0E2E4`,
    listStyle: 'none',
    padding: 0,
    mt: [7],
    pb: [7],
    maxWidth: 340,
    li: {
      display: 'flex',
      gap: 2,
      alignItems: 'flex-start',
      fontSize: 16,
      lineHeight: 1.62,
      'span:first-of-type': {
        mt: '5px',
      },
    },
    '.unavailable': {
      opacity: 0.5,
    },
  },
  button: {
    backgroundColor: rgba('#7536C3', 0.1),
    color: '#7536C3',
    px: 25,
    minHeight: "45px !important",
    mt: [7],
    ':hover': {
      backgroundColor: '#7536C3',
      color: '#ffffff',
    },
  },
};

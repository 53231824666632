import React from 'react';
import { rgba } from 'polished';

const Suitcase = ({ fill, ...props }) => {
  return (
    <svg
      width="30"
      height="30" viewBox="0 0 30 30"
      stroke={fill ?? rgba('#FFFFFF', 0.3)}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill ?? rgba('#FFFFFF', 0.3)}
      {...props}
    >
      <path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path>
      <line x1="16" y1="8" x2="2" y2="22"></line>
      <line x1="17.5" y1="15" x2="9" y2="15"></line>
    </svg>
  );
};

export default Suitcase;

import React from 'react';
import { rgba } from 'polished';

const Smartphone = ({ fill, ...props }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill ?? rgba('#FFFFFF', 0.3)}
      stroke={fill ?? rgba('#FFFFFF', 0.3)}
    >
      <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
      <line x1="12" y1="18" x2="12.01" y2="18"></line>
    </svg>
  );
};

export default Smartphone;

/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import { useI18next } from 'gatsby-plugin-react-i18next';

const Subscribe = () => {
  const { t } = useI18next("index");

  return (
    <Box as="section" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title={t("subscribe.title")}
          description={t("subscribe.description")}
        />
        <Box sx={styles.contact}>
          <Box>{t("subscribe.contact.question")}</Box>
          <Box className={"link"} as={"a"} href={"tel://+213770433804"}>
            {t("subscribe.contact.sugestion")}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Subscribe;

const styles = {
  section: {
    backgroundColor: '#111',
    pt: [9, null, null, null, 10, 13, null],
    pb: [9, null, null, null, 11, 14, null],
  },
  heading: {
    mb: [7, null, null, 8, 9, 10],
    h2: {
      color: 'white',
      fontSize: [6, null, null, 8],
    },
    p: {
      color: '#858B91',
      fontSize: [2, null, null, 3],
      m: ['15px auto 0', null, null, '10px auto 0'],
    },
  },
  contact: {
    color: "#858B91",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    lineHeight: 2.2,
    ".link": {
      color: "#ffffff",
      fontWeight: "900"
    }
  }
};

/** @jsx jsx */
import { useI18next } from 'gatsby-plugin-react-i18next';
import { jsx, Box, Heading, Text, Button } from 'theme-ui';
import Img from 'components/image';

const TabContent = ({ tabContent, image, openModal }) => {
  const { t } = useI18next();
  return tabContent.map((item, index) => (
    <Box key={index} sx={styles.tabContent}>
      <Box sx={styles.content}>
        <Heading>{t(item.title)}</Heading>
        <Text as="p">{t(item.description)}</Text>
        <Button
          as={"a"}
          href={`#`}
          onClick={openModal}
          // target={"_blank"}
          rel={"noopener noreferrer"}
          sx={styles.actionButton}
        >
          {t("signup")}
        </Button>
      </Box>
      <Box
        sx={styles.illustration}
      >
        <Img
          src={image}
          alt={t("banner.illustration")}
          fetchpriority={"high"}
          loading={"eager"}
        />
      </Box>
    </Box>
  ));
};

export default TabContent;

const styles = {
  tabContent: {
    display: ['flex'],
    flexDirection: ['column-reverse', null, null, null, 'row'],
    alignItems: 'flex-start',
  },
  content: {
    maxWidth: [480, null, null, 580, 480],
    ml: [null, null, null, 'auto', 'unset'],
    mr: [0, null, null, 'auto', 10, 8, 10],
    textAlign: [null, null, null, 'center', 'left'],
    h2: {
      fontWeight: 700,
      fontSize: [6, null, null, 10],
      lineHeight: 1.5,
      letterSpacing: 'heading',
    },
    p: {
      fontSize: [1, null, null, 2],
      lineHeight: [1.8],
      mt: [5],
      mb: [5],
    },
    button: {
      mt: [6, null, null, null, 7, null, 9],
    },
  },
  actionButton: {
    minHeight: "45px !important",
    backgroundColor: "#7536c3",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#2b9ef8",
    }
  },
  illustration: {
    alignItems: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    minHeight: [256, null, null, 538, 470, 510, 633],
    mb: [5, null, null, null, 0],
    width: '100%',
    borderRadius: 0,
    borderTopLeftRadius: 20,
    overflow: "hidden",
    position: "relative",
    '& > div': {
      position: "absolute !important",
      right: 0,
      top: 0,
      left: 0,
      bottom: 0,
      "& img": {
        objectPosition: "left !important",
      }
    }
  },
};

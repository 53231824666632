/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import SectionHeading from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
import Image from 'components/image';
import { useI18next } from 'gatsby-plugin-react-i18next';

const data = [
  {
    title: 'accordion.part0.title',
    contents: 'accordion.part0.content'
  },
  {
    title: 'accordion.part1.title',
    contents: 'accordion.part1.content'
  },
  {
    title: 'accordion.part2.title',
    contents: 'accordion.part2.content'
  },
];

const PremiumFeature = () => {
  const { t } = useI18next();
  const image = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "boost-sales.png" }) {
        childImageSharp {
          fluid(toFormat: WEBP) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section id="features" sx={styles.section}>
      <Container>
        <Grid sx={styles.grid}>
          <Box as="figure" sx={styles.illustration}>
            <Image
              src={image.illustration.childImageSharp.fluid}
              title={t("accordion.imageAlt")}
              alt={t("accordion.imageAlt")}
            />
          </Box>
          <Box sx={styles.rightContent}>
            <SectionHeading
              sx={styles.heading}
              title={t("accordion.title")}
              description={t("accordion.description")}
            />
            <Box sx={styles.accordionGroup}>
              <Accordion items={data} />
            </Box>
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default PremiumFeature;

const styles = {
  section: {
    pt: [6, null, null, 6, 8, 9],
    pb: [6, null, null, null, 7, 9, 11, null],
    backgroundColor: "rgba(117, 54, 195, 0.05)"
  },
  grid: {
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      null,
      null,
      null,
      '1fr 431px',
      '1fr 530px',
      '1fr 550px',
    ],
  },
  heading: {
    textAlign: "initial",
    ml: [null, null, null, 'auto'],
    maxWidth: [null, null, null, 520, 660],
    h2: {
      fontSize: [null, null, null, 10, 8, 40],
      img: {
        maxWidth: [24, null, null, 30, 25, null, '100%'],
        top: ['4px', '8px', null, null, '4px', '8px'],
      },
    },
    p: {
      fontSize: [null, null, null, 2],
    },
  },
  illustration: {
    mb: [-6, null, null, -8, 0],
    position: 'relative',
    maxHeight: "500px !important",
    "& img": {
      maxHeight: ["500px !important"],
      objectFit: "contain !important"
    },
    '@media screen and (max-width: 1024px)': {
      display: 'none',
    },
  },
  accordionGroup: {
    m: [null, null, null, '0 auto', 'unset'],
    maxWidth: [null, null, null, 600, 'none'],
  },
};

import React from 'react';
import { rgba } from 'polished';

const PieChart = ({ fill, ...props }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30"
      stroke={fill ?? rgba('#FFFFFF', 0.3)}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill ?? rgba('#FFFFFF', 0.3)}
      {...props}
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
  );
};

export default PieChart;

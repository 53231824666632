import React from 'react';
import { rgba } from 'polished';

const Zap = ({ fill, ...props }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={fill ?? rgba('#FFFFFF', 0.3)}
      {...props}
    >
      <polygon
        fill={fill ?? rgba('#FFFFFF', 0.3)}
        points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"
      ></polygon>
    </svg>
  );
};

export default Zap;

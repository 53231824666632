import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import PremiumFeature from 'sections/premium-feature';
import AppFeature from 'sections/app-feature';
import Dashboard from 'sections/dashboard';
import Pricing from 'sections/pricing';
import Testimonials from 'sections/testimonials';
import MobileApp from 'sections/mobile-app';
import Subscribe from 'sections/subscribe';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Modal from 'react-modal';
import { Box, Button } from 'theme-ui';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

export default function IndexPage({ data: { pageImage } }) {
  const { t, language } = useI18next("index");
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Layout openModal={openModal}>
      <SEO
        title={`Zzenz.com ${t("site.title")}`}
        description={t("site.description")}
        image={pageImage?.childImageSharp?.fluid?.src}
        lang={language}
      />
      <Banner openModal={openModal} />
      <button onClick={openModal}>Open Modal: {modalIsOpen}</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={t("contact_modal_title")}
      >
        <h2>{t("contact_modal_title")}</h2>
        <Box sx={{
          display: "flex", gap: ".5rem", a: {
            minHeight: "2rem",
            padding: "0 1rem",
          }
        }}>
          <Button
            as={"a"}
            target={"_blank"}
            href={`https://x.com/zzenzit`}
            sx={{ backgroundColor: "#051613" }}
          >
            {t("twitter")}
          </Button>
          <Button
            as={"a"}
            target={"_blank"}
            href={`https://t.me/DayenIO`}
            sx={{ backgroundColor: "#3390ec" }}
          >
            {t("telegram")}
          </Button>
          <Button
            as={"a"}
            target={"_blank"}
            href={`https://wa.me/213770433804`}
            sx={{ backgroundColor: "#00E676" }}
          >
            {t("whatsapp")}
          </Button>
          <Button
            as={"a"}
            target={"_blank"}
            href={`tel://213770433804`}
            sx={{ backgroundColor: "#7536c3" }}
          >
            {t("phone")}
          </Button>
          <Button
            as={"a"}
            onClick={closeModal}
            href={`#`}
            sx={{
              backgroundColor: "white", color: "#d00", border: "1px solid #d00", ":hover": {
                backgroundColor: "#d00",
                color: "white"
              }
            }}
          >
            {t("close")}
          </Button>
        </Box>
      </Modal>
      <PremiumFeature />
      <AppFeature />
      <Dashboard openModal={openModal} />
      <Pricing openModal={openModal} />
      <MobileApp />
      <Testimonials />
      <Subscribe />
    </Layout >
  );
}

export const query = graphql`
  query HomeQuery($language: String!) {
    pageImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["index", "header", "common"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
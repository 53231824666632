/** @jsx jsx */
import { useRef, useEffect, useState } from 'react';
import { rgba } from 'polished';
import { jsx, Box, Container } from 'theme-ui';
import Tabs, { TabPane } from 'rc-tabs';
import 'rc-tabs/assets/index.css';
import TabTitle from 'components/tabs/tab-title';
import TabContent from 'components/tabs/tab-content';
import Currency from 'components/icons/currency';
import Cog from 'components/icons/cog';
import PieChart from 'components/icons/pie-chart';
import Suitcase from 'components/icons/suitcase';
import Zap from 'components/icons/zap';
import Smartphone from 'components/icons/smartphone';
import BarChart from 'components/icons/bar-chart';
import dashboard from 'assets/images/dashboard/1-stats.png';
import { graphql, useStaticQuery } from 'gatsby';

const data = [
  {
    id: 1,
    tabPane: [
      {
        icon: <Currency />,
        title: 'dashboard.part0.tabPane.title',
      },
    ],
    tabContent: [
      {
        image: dashboard,
        title: 'dashboard.part0.tabContent.title',
        description: 'dashboard.part0.tabContent.description',
        readMore: 'dashboard.part0.tabContent.readMore',
      },
    ],
  },
  {
    id: 2,
    tabPane: [
      {
        icon: <Cog />,
        title: 'dashboard.part1.tabPane.title',
      },
    ],
    tabContent: [
      {
        image: dashboard,
        title: 'dashboard.part1.tabContent.title',
        description: 'dashboard.part1.tabContent.description',
        readMore: 'dashboard.part1.tabContent.readMore',
      },
    ],
  },
  {
    id: 3,
    tabPane: [
      {
        icon: <PieChart />,
        title: 'dashboard.part2.tabPane.title',
      },
    ],
    tabContent: [
      {
        image: dashboard,
        title: 'dashboard.part2.tabContent.title',
        description: 'dashboard.part2.tabContent.description',
        readMore: 'dashboard.part2.tabContent.readMore',
      },
    ],
  },
  {
    id: 4,
    tabPane: [
      {
        icon: <Suitcase />,
        title: 'dashboard.part3.tabPane.title',
      },
    ],
    tabContent: [
      {
        image: dashboard,
        title: 'dashboard.part3.tabContent.title',
        description: 'dashboard.part3.tabContent.description',
        readMore: 'dashboard.part3.tabContent.readMore',
      },
    ],
  },
  {
    id: 5,
    tabPane: [
      {
        icon: <BarChart />,
        title: 'dashboard.part4.tabPane.title',
      },
    ],
    tabContent: [
      {
        image: dashboard,
        title: 'dashboard.part4.tabContent.title',
        description: 'dashboard.part4.tabContent.description',
        readMore: 'dashboard.part4.tabContent.readMore',
      },
    ],
  },
  {
    id: 7,
    tabPane: [
      {
        icon: <Zap />,
        title: 'dashboard.part6.tabPane.title',
      },
    ],
    tabContent: [
      {
        image: dashboard,
        title: 'dashboard.part6.tabContent.title',
        description: 'dashboard.part6.tabContent.description',
        readMore: 'dashboard.part6.tabContent.readMore',
      },
    ],
  },
  {
    id: 6,
    tabPane: [
      {
        icon: <Smartphone />,
        title: 'dashboard.part5.tabPane.title',
      },
    ],
    tabContent: [
      {
        image: dashboard,
        title: 'dashboard.part5.tabContent.title',
        description: 'dashboard.part5.tabContent.description',
        readMore: 'dashboard.part5.tabContent.readMore',
      },
    ],
  },
];

const Dashboard = ({ openModal }) => {
  const containerRef = useRef();
  const [containerOffset, setContainerOffset] = useState({
    left: null,
    top: null,
  });
  const dashboardImages = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {relativeDirectory: {eq: "dashboard"}}
        sort: {fields: [name]order: ASC}
      ) {
        nodes {
          childImageSharp {
            fluid(toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
`);

  useEffect(() => {
    setContainerOffset({
      left: containerRef.current.offsetLeft,
      top: containerRef.current.offsetTop,
    });
  }, [containerRef]);

  return (
    <Box as="section" sx={styles.section} dir={"ltr"}>
      <Container ref={containerRef} />
      <Box sx={{ pl: containerOffset.left + 30, ...styles.container }}>
        <Tabs
          sx={styles.tabs}
          animated={{ tabPane: true }}
          defaultActiveKey="1"
        >
          {data?.map((tab, index) => (
            <TabPane key={tab?.id} tab={<TabTitle tab={tab.tabPane} />}>
              <TabContent
                openModal={openModal}
                tabContent={tab?.tabContent}
                image={dashboardImages.images.nodes[index].childImageSharp.fluid}
              />
            </TabPane>
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default Dashboard;

const styles = {
  section: {
    backgroundColor: '#000022',
    pt: [9, null, null, 11, 10, 12, null],
    pb: [9, null, null, null, 0],
  },
  container: {
    maxWidth: ['none !important'],
    pr: [6, null, null, 0],
  },
  tabs: {
    border: 0,
    color: 'white',
    '.rc-tabs-nav': {
      mb: [8, null, null, 7, 10, null, 12],
    },
    '.rc-tabs-nav-wrap': {
      '::before': {
        backgroundColor: rgba('#fff', 0.1),
        content: ['none', null, null, `''`],
        height: 1,
        position: 'absolute',
        left: 0,
        top: 51,
        width: '100%',
      },
      '::after': {
        borderColor: ['primary'],
      },
    },
    '.rc-tabs-nav-list': {
      display: ['flex'],
    },
    '.rc-tabs-tab': {
      backgroundColor: 'transparent',
      '+ .rc-tabs-tab': {
        ml: [5, null, null, 5, 8, 12],
      },
      'svg g, svg path': {
        transition: '0.3s ease-in-out 0s',
      },
    },
    '.rc-tabs-tab-btn': {
      alignItems: 'center',
      display: ['flex', null, null, 'block'],
      outline: '0 none',
      fontSize: [null, null, null, 15, 2],
    },
    '.rc-tabs-tab-active': {
      'svg g, svg path, svg polygon, svg rect': {
        fill: '#7536c3',
        opacity: 1,
      },
      h5: {
        color: 'primary',
      },
    },
    '.rc-tabs-ink-bar': {
      backgroundColor: '#7536c3',
      borderRadius: 5,
      bottom: [47],
      display: ['none', null, null, 'block'],
    },
    '.rc-tabs-tabpane': {
      outline: '0 none',
    },
  },
};

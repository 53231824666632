import React from 'react';
import { rgba } from 'polished';

const Currency = ({ fill, ...props }) => {
  return (
    <svg
      width="24" height="24" viewBox="0 0 24 24"
      stroke={fill ?? rgba('#FFFFFF', 0.3)}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill ?? rgba('#FFFFFF', 0.3)}
      {...props}
    >
      <circle cx="9" cy="21" r="1"></circle>
      <circle cx="20" cy="21" r="1"></circle>
      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
    </svg>
  );
};

export default Currency;

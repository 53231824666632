import React from 'react';
import { rgba } from 'polished';

const Currency = ({ fill, ...props }) => {
  return (
    <svg
      fill={fill ?? rgba('#FFFFFF', 0.3)}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      stroke={fill ?? rgba('#FFFFFF', 0.3)}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-credit-card"
      {...props}
    >
      <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
      <line x1="1" y1="10" x2="23" y2="10"></line>
    </svg>
  );
};

export default Currency;
